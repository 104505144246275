<template>
  <div
    class="banner w-full desktop:w-[calc(100%+48px)] pt-0.5 px-1.5 absolute top-0 left-0 desktop:-left-6 translate-y-0 desktop:-translate-y-full text-center bg-primary-100"
  >
    <TextTiny white class="desktop:!text-sm">
      <i18n-t keypath="customer.banner.book_meeting.title" tag="p">
        <template #link>
          <a
            class="text-white underline"
            href="https://meetings.hubspot.com/lh21/sales-round-robin-"
            target="_blank"
          >
            {{ $t('shared.button.here') }}
          </a>
        </template>
      </i18n-t>
    </TextTiny>
  </div>
</template>
<script setup>
import TextTiny from '@/components/shared/font/text/TextTiny'
import i18n from '@/i18n'

const $t = i18n.t
</script>
